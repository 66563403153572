import { useEffect } from 'react';
import { useUser, useClasses } from 'lib/hooks';

const Hotjar = () => {
  const { user } = useUser();

  const { classes, areClassesLoading } = useClasses();

  useEffect(() => {
    if (
      typeof window.hj !== 'undefined' &&
      user &&
      user.roles &&
      user.roles.length !== 0 &&
      !areClassesLoading
    ) {
      const lyceeStudies: any = user.lyceeStudies;
      lyceeStudies?.sort((a, b) => b.annee - a.annee);

      let profHasDoneModules = false;
      let profVoiePro = false;

      if (user.roles[0] === 'pedagogie') {
        if (classes) {
          classes.forEach((classe) => {
            if (classe.modulesCounter) profHasDoneModules = true;
          });
        }

        if (user.profile?.pedagogie_filiere_professionnelle) profVoiePro = true;
      }

      window.hj('identify', user.id, {
        Rôle: user.roles[0],
        date_inscription: user.createdAt,
        niveau:
          user.lyceeStudies && user.roles[0] === 'lyceen'
            ? user?.lyceeStudies[0]?.niveau
            : null,
        type_bac:
          user.lyceeStudies && user.roles[0] === 'lyceen'
            ? user?.lyceeStudies[0]?.filiere
            : null,
        profHasDoneModules:
          user.roles[0] === 'pedagogie' ? profHasDoneModules : null,
        profVoiePro: user.roles[0] === 'pedagogie' ? profVoiePro : null,
      });
    }
  }, [user]);
  return null;
};

// const Hotjar = () => {
//   const { user } = useUser();
//   const { classes, areClassesLoading } = useClasses();

//   useEffect(() => {
//     if (
//       typeof window.hj !== 'undefined' &&
//       user &&
//       user.roles &&
//       user.roles.length !== 0 &&
//       !areClassesLoading
//     ) {
//       const lyceeStudies: any = user.lyceeStudies;
//       lyceeStudies?.sort((a, b) => b.annee - a.annee);

//       let profHasDoneModules = false;

//       if (user.roles[0] === 'pedagogie' && classes) {
//         console.log('classes', classes);
//         classes.forEach((classe) => {
//           if (classe.modulesCounter) profHasDoneModules = true;
//         });
//       }

//       window.hj('identify', user.id, {
//         Rôle: user.roles[0],
//         date_inscription: user.createdAt,
//         niveau:
//           user.lyceeStudies && user.roles[0] === 'lyceen'
//             ? user.lyceeStudies[0].niveau
//             : null,
//         type_bac:
//           user.lyceeStudies && user.roles[0] === 'lyceen'
//             ? user.lyceeStudies[0].filiere
//             : null,
//         profHasDoneModules:
//           user.roles[0] === 'pedagogie' ? profHasDoneModules : null,
//       });
//     }
//   }, [user]);
//   return null;
// };

export default Hotjar;
